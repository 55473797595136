.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  @media screen and (max-width: 770px) {
    .title {
      display: block;
    }
  }
  