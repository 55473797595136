.menu {
    width:400px;
    line-height:50px;
    padding:10px;
    font-size:16px;
    font-weight:bold;
    margin:10px;
    background:#aa1d1d;
    border:solid 3px #000;
  }
  
  .extra {
    display:none;
  }
  
  .menu:hover .extra,
  .menu:focus .extra {
    display:block;
  }