/* .customCategory {
    width: 1000px;
} */


@media screen and (max-width: 730px) {
    .customCategory {
      width: 80px;
      font-size: 8px;
      
    }
  }