.form {
  margin: 0 auto 50px;
}

.ingredientsInputs {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ingredientsInput {
  width: 230px;
}

.ingredientsAmountInputContainer {
  display: flex;
}

.ingredientsLabel {
  min-width: 230px;
}

.measurementUnit {
  align-self: center;
  margin-left: 20px;
}

.cookingTime {
  display: flex;
}

.cookingTimeUnit {
  align-self: center;
  margin-bottom: 35px;
}

.cookingTimeLabel {
  min-width: 230px;
}

.ingredientsAmountValue {
  max-width: 100px;
}

.ingredientsTimeInput {
  margin-right: 10px;
}

.ingredientsNameInput,
.ingredientsAmountInput,
.measurementUnit {
  margin-bottom: 20px;
}

.ingredientAdd {
  cursor: pointer;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 230px;
  text-decoration: underline;
}

.ingredientsAdded {
  padding-left: 230px;
  text-align: left;
}

.ingredientsAddedItemTitle {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 4px;
}

.fileInput {
  margin-bottom: 72px;
}

.button {
  font-size: 18px;
}

.ingredientsAddedItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.ingredientsAddedItemRemove {
  padding-left: 20px;
  color: red;
  cursor: pointer;
}
.checkboxGroup {
  width: 600px;
  overflow: auto;
  padding: 10px;
}

.checkboxGroupItem {
  margin-bottom: 6px;
  
}

@media screen and (max-width: 820px) {
  .ingredientsInputs {
    display: block;
    text-align: left;
  }

  .ingredientAdd {
    padding-left: 0px;
  }

  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .checkboxGroupTags {
    display: block;
  }

  .checkboxGroupItem {
    margin-bottom: 6px;
  }

  .cookingTime {
    display: block;
    text-align: left;
  }

  .ingredientsAdded {
    padding-left: 0px;
  }
}