.footer {
  background: #000000;
  height: 90px;
  flex: 0 0 auto;
  margin-top: 50px;
}

.footer__brand {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.footer__item {
    margin-right: 40px;
}

.footer__items {
    display: flex;
    list-style: none;
    padding: 0;
}

.footer__link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
