.card {
    display: flex;
    flex-direction: column;
    max-width: auto;
    padding: 10px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    text-align: left;
  }
  
  .card__add {
    display: flex;
    font-size: 14px!important;
    font-weight: 600!important;
    align-items: center;
  }
  
  .card__add svg {
    margin-right: 5px;
  }
  
  .card__body {
    padding: 24px 24px 12px;
    flex: 1 0 auto;
  }
  
  .card__link {
    color: #000;
  }
  
  .card__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 24px 32px;
      flex: 0 0 auto;
  }
  
  .card__item {
      margin-right: 8px;
  }
  .card__text {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      margin: 0 0 15px;
      padding: 0;
  }
  .card__text:last-child {
      margin-bottom: 0;
  }
  
  .card__image {
    height: 340px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  
  .card__title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 12px;
    padding: 0;
    display: block;
    text-align: left;
  }
  
  .card__time,
  .card__author {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .card__time {
    margin-bottom: 15px;
  }
  
  .card__time svg,
  .card__author svg {
    margin-right: 10px;
  }
  
  
  @media screen and (max-width: 1000px) {
    .card__image {
      height: 160px;
    }
  
    .card__body {
      padding: 12px;
    }
  
    .card__footer {
      padding: 12px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .card {
      max-width: 100%;
    }
  }
  
  