.metkatop {
    display: block;
     /* это наверное уже у тебя в js будет вычисляться */
    width: 64px;
    height: 34px; 
    position: absolute;       
    margin: 10px;
    border-radius: 10px;
    background: url(./images/img_top_metka.png);
    /* background-image: url(./images/img_top_metka.png) no-repeat;  */
    background-position: center;
    background-size: cover;
    /* background-color: #dab31b; */
    text-align: center;

}
