.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 820px) {
  .header {
    margin-bottom: 1px;
  }
}